import { User, useFeatureFlag } from 'configcat-react';

export const CONFIG_CAT_KEYS = {
    authApiKeyAllowDisable: 'authApiKeyAllowDisable',
    paymentV2InitiateRefund: 'paymentV2InitiateRefund',
    enableCreateUserForSubTpp: 'enableCreateUserForSubTpp',
    enableVASettlementTransactionsView: 'enableVASettlementTransactionsView',
    enablePaymentDescriptionFilter: 'enablePaymentDescriptionFilter',
} as const;

export interface ICONFIG_CAT_VALUES {
    authApiKeyAllowDisable: boolean;
    paymentV2InitiateRefund: boolean;
    enableCreateUserForSubTpp: boolean;
    enableVASettlementTransactionsView: boolean;
    enablePaymentDescriptionFilter: boolean;
}

export interface ReturnType<T extends keyof typeof CONFIG_CAT_KEYS> {
    value: ICONFIG_CAT_VALUES[T];
    loading: boolean;
}

export default function useAppFeatureFlag<T extends keyof typeof CONFIG_CAT_KEYS>(
    key: T,
    flagDefaultValue?: ICONFIG_CAT_VALUES[T],
    user?: User,
): ReturnType<T> {
    const { value, loading } = useFeatureFlag(key, flagDefaultValue, user);

    const typedValue = value as ICONFIG_CAT_VALUES[T];

    return { value: typedValue, loading };
}
